<script setup lang="ts"></script>

<template>
  <div class="min-h-[8rem] md:min-h-[25rem] md:max-w-[23rem] w-full md:min-w-[23rem]">
    <div
      class="flex md:flex-col h-full overflow-hidden relative w-full md:max-w-[23rem] group/item hover:shadow-xl shadow-neutral-darkest rounded-2xl last:duration-500 cursor-pointer opacity-45"
    >
      <div
        class="hidden md:block md:rounded-t-2xl aspect-square w-1/3 md:w-auto md:aspect-[4/3] relative overflow-hidden z-10 bg-neutral skeleton"
      >
        <div class="absolute peer w-full h-full overflow-hidden image"></div>
      </div>
      <div
        class="-mt-1 flex-1 flex flex-col bg-primary md:rounded-b-2xl px-6 md:px-6 py-6 md:py-8 relative z-10"
      >
        <h3
          class="font-body text-lg md:text-xl font-bold mb-2 md:mb-6 text-white glow:text-glow bg-neutral-light h-8 opacity-45 skeleton"
        ></h3>
        <p
          class="flex-1 min-h-14 font-body text-sm md:text-md text-white mb-4 md:mb-6 bg-neutral-light opacity-45 skeleton"
        ></p>
        <div class="mb-2 md:mb-4 flex items-end">
          <ul class="bg-neutral-light w-full h-6 opacity-45 skeleton"></ul>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.skeleton {
  background: #969696da;
  background-repeat: no-repeat;
  background: linear-gradient(110deg, #ececec 8%, #dfdfdfde 18%, #ececec 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
}
@keyframes shine {
  to {
    background-position-x: -200%;
  }
}
</style>
